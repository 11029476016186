
import AuthPage from "./AuthPage";

export default function AuthRouter(){
    return (
        <div className="backpage">
            <AuthPage />
        </div>
    )
}
